<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="1000px">
        <v-card>
          <v-toolbar
            color="primary"
            dark
            flat
            style="padding-right: 10px !important"
          >
            <v-toolbar-title class="overline">{{
              titleParticipantModal
            }}</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    outlined
                    :items="members"
                    item-value="id"
                    item-text="person.corporateName"
                    v-model="id"
                    label="Membro"
                    :loading="loading"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  dark
                  @click="registerOrUpdateParticipant()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="searchParticipants"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openModalType()"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Membro</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                @click="openModalType()"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headerParticipants"
          :items="membersGroup"
          :search="searchParticipants"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :to="`/members/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
            <v-icon
              v-if="verifyDeletePermission()"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";

export default Vue.extend({
  name: "GroupMembers",
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Membros",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Grupos",
        disabled: false,
        href: "/promise/cells",
      },
      {
        text: "Membros",
        disabled: true,
        href: "/members",
      },
    ],
    deleteDialog: false,
    search: "",
    searchParticipants: "",
    members: [],
    loading: false,
    loadingAction: false,
    titleParticipantModal: "Novo Participante",
    membersGroup: [],
    group: {
      id: null,
    },
    groupId: null,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    id: "",
    openDialog: false,
    participantModal: false,
    headerParticipants: [
      {
        text: "Nome",
        align: "start",
        value: "person.corporateName",
        filtering: true,
      },
      {
        text: "WhatsApp",
        align: "start",
        value: "contact.whatsApp",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    registerOrUpdateParticipant() {
      const paload = { groupId: this.groupId };
      http.patch(`gerentor/customers/${this.id}`, paload).then(
        (res) => {
          this.snackbar.text = "Membro adicionado com sucesso";
          this.snackbar.color = "green";
          this.snackbar.opened = true;
          this.openDialog = false;
          this.getParticipants();
        },
        () => {
          this.snackbar.text = "Erro ao atualizar participante";
          this.snackbar.color = "red";
          this.snackbar.opened = true;
        }
      );
    },
    async getParticipants() {
      this.loading = true;
      http.get(`gerentor/customers?filter=groupId||eq||${this.groupId}`).then(
        (res) => {
          const { data } = res;
          this.membersGroup = data;
          this.loading = false;
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Falha ao buscar Membros";
          this.snackbar.opened = false;
          this.loading = false;
        }
      );
    },
    closeDialog() {
      this.id = null;
      this.openDialog = false;
    },
    openModalType(group) {
      if (!group && this.group.id) group = this.group;
      this.openDialog = true;
    },
    editParticipant(participant) {
      this.id = participant.id;
      this.titleParticipantModal =
        "Atualizar Membro " + participant.person.corporateName;
      this.openDialog = true;
    },
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions?.map((a)=> a.id)?.includes(
          "digitech_plus.immobile.members.delete_one"
        )
      ) {
        return true;
      }
      return false;
    },
    getAllMembers() {
      http.get("gerentor/customers").then((res) => {
        this.members = res.data.filter((a) => !this.membersGroup.includes(a));
      });
    },
    deleteItem() {
      const payload = {
        groupId: null,
      };
      http.patch(`gerentor/customers/${this.id}`, payload).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.id = null;
          this.deleteDialog = false;
          this.getParticipants(this.group);
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    async getItems() {
      this.loading = true;
      const groupId = this.$route.params.groupId;
      if (!groupId) {
        this.snackbar.color = "red";
        this.snackbar.text = "Falha ao Localizar Grupo";
        this.snackbar.opened = true;
        this.loading = false;
        return;
      }
      this.groupId = groupId;
      http.get(`promise/cells/${groupId}`).then(
        async (data) => {
          this.group = data.data;
          this.title = `Membros ${this.group.name}`;
          await this.getParticipants();
          this.loading = false;
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Falha ao Localizar Grupo";
          this.snackbar.opened = true;
          this.loading = false;
        }
      );
    },
  },
  mounted() {
    this.getItems();
    this.getAllMembers();
  },
});
</script>
